<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-login" >
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="login-find-wrap">
          <div class="tab-box">
            <router-link to="/find/findId">아이디 찾기</router-link>
            <router-link to="/find/findPwdId" class="on">비밀번호 재설정</router-link>
          </div>
          <div class="find-form-box">
            <div class="result-box">
              비밀번호가 안전하게 변경되었습니다.<br>새로운 비밀번호로 로그인해주세요.
            </div>
          </div>
          <div class="btn-box st-result">
            <router-link to="/login" class="btn-submit">로그인</router-link>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
export default {
  name: "findPwdResult",
  methods: {
  }
}
</script>

<style scoped>

</style>